.button {
    color: rgb(255 255 255);
    font-weight: 600;
    padding: 1rem;
    border-width: 4px;
    border-radius: 0.25rem;
    width: fit-content;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.buttonRed {
    background-color: rgb(220 38 38);
    border-color: rgb(239 68 68 /0.8);
}

.buttonAmber {
    background-color: rgb(217 119 6);
    border-color: rgb(245 158 11/0.8);
}

.buttonGreen {
    background-color: rgb(22 163 74);
    /* .bg-green-600 */
    border-color: rgb(34 197 94/0.8);
    /* .bg-green-500 */
}

.buttonIndigo {
    background-color: rgb(79, 70, 229);
    /* .bg-indigo-600 */
    border-color: rgb(99 102 241/0.8);
    /* .bg-indigo-500 */
}

.opacity50 {
    opacity: 0.5;
}

.opacity70 {
    opacity: 0.7;
}