
.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

.fade-in.show {
    opacity: 1;
  } 

  .fade-in-section {
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation: fadeInAnimation ease 2s;
  }
  
  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
  
    20% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
    
    100% {
        opacity: 1;
    }
  }
  