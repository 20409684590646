.popupWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, transform 0.3s;
    transform: scale(0);
    backdrop-filter: blur(4px);
    z-index:40;
  }

.popup-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  
  .popup-hidden {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
  }
  

.popupBackground {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    padding: 2rem;
    background-color: rgb(255 255 255);
    border-radius: 0.5rem;
}

.popupHeading {
    color: rgb(220 38 38);
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1rem;
}

