

.memoryGame {
    max-width: 860px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.memoryButton {
    background: rgb(67 56 202);
    padding:6px 12px;
    border: 2px solid rgb(99 102 241);
    border-radius: 0.25rem;
    color: #fff;
    font-weight:600;
    cursor: pointer;
    font-size: 1rem;
}

.memoryButton:hover {
    background: rgb(55 48 163);
    color: #fff;
}

.card-grid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}