.dropdownBox {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    margin-top: 0.5rem;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}