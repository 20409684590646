.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    /* flex flex-col items-center justify-start py-4 */
}

.wrapper {
    width: 400px;
    padding: 1.5rem;
    background-color: rgb(15 23 42);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* bg-slate-900 flex flex-col justify-center items-center p-6 rounded-lg shadow-lg */
}