.screen {
    height: 80px;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-weight: bold;
    font-size: xx-large;
    box-sizing: border-box;
    background-color: rgb(30 41 59);
  }