.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.wrapper {
    width: 340px;
    height: 500px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgb(15 23 42);
  }
