
.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

.fade-in.show {
    opacity: 1;
  } 

.fade-in-section {
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#section-1 {
  animation: fadeInAnimation ease 2s;

}

#section-2 {
  animation: fadeInAnimation ease 2.5s;
}

#section-3 {
  animation: fadeInAnimation ease 3s;
}

#section-4 {
  animation: fadeInAnimation ease 3.5s;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }

  20% {
    opacity: 0;
  }
  
  100% {
      opacity: 1;
  }
}
