.timerBox {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
}

.timer {
    color: rgb(255 255 255);
    font-size: 3.75rem;
    line-height: 1;
    gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(11, minmax(0, 1fr));
    margin-top: 1rem;

    /* text-6xl text-white grid grid-cols-11 gap-2 mt-4 */
}

.number {
    justify-content: center;
    align-items: center;
    display: flex;
    grid-column: span 2 / span 2;

    /* col-span-2 flex items-center justify-center */
}

.colon {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 1 / span 1;
}